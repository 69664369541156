export const CATALOG_TRACKER_VIEW_TYPE_DATA = {
  name: "competitive_retail_tracker",
  id: 9,
};

export const CELL_VALUE_CLASSIFICATION = {
  single: 0,
  multiple: 1,
};

export const CATALOG_TRACKER_VALUE_TYPE = {
  number: 0,
  price_diff: 1,
  number_diff: 3,
  average: 4,
  oos: 5,
  index: 6,
  ratings_and_avg: 7,
  promo: 8,
};

export const DEFAULT_CATALOG_TRACKER_VALUE_TYPE = "On Promo";

export const INDEX_COLORS = [
  "#5771F4",
  "#D871D4",
  "#41CAC2",
  "#7FB7F5",
  "#3CC4FF",
  "#8F3F3F",
  "#B36B6B",
  "#E6A3A3",
  "#6B8FB3",
  "#A3C1E6",
  "#4DA361",
  "#8FD18F",
  "#D1F0D1",
  "#B38FB3",
  "#E6B3E6",
  "#B3B38F",
  "#E6E6A3",
  "#8F6B4D",
  "#E6C4A3",
];

const path = "catalog-tracker";
export const GET_MATCHES_REQUEST = `${path}/GET_MATCHES_REQUEST`;
export const GET_MATCHES_SUCCESS = `${path}/GET_MATCHES_SUCCESS`;
export const GET_MATCHES_CANCEL = `${path}/GET_MATCHES_CANCEL`;
export const GET_MATCHES_FAILURE = `${path}/GET_MATCHES_FAILURE`;

export const GET_MORE_MATCHES_REQUEST = `${path}/GET_MORE_MATCHES_REQUEST`;
export const GET_MORE_MATCHES_SUCCESS = `${path}/GET_MORE_MATCHES_SUCCESS`;
export const GET_MORE_MATCHES_CANCEL = `${path}/GET_MORE_MATCHES_CANCEL`;
export const GET_MORE_MATCHES_FAILURE = `${path}/GET_MORE_MATCHES_FAILURE`;

export const UPDATE_MAIN_ROOTDOMAIN = `${path}/UPDATE_MAIN_ROOTDOMAIN`;
