import React, { Component } from "react";
import Modal from "react-modal";
import { OktaAuth } from '@okta/okta-auth-js';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { OKTA_USERS_URL } from "../../../config";

import styles from "./login.scss";
import utils from "../../../utils";
import {
  changeField,
  trySubmitted,
  changeFieldErrorStatus,
  sendForm,
  thirdPartyAuthenticate,
  supportPortalAuth,
} from "./actions";

import logo from "../../../images/bi-logo-big.png";
import visibilityOn from "../../../images/eye-visibility-on.svg";
import visibilityOff from "../../../images/eye-visibility-off.svg";
import Spinner from "../../../components/spinner";
import { AuthType } from "../constants";
Modal.setAppElement('#root');

const { getToken } = utils.auth; 
class Login extends Component {
  state = {
    isVisiblePassword: false,
    showSSOModal: false, 
    ssoEmail: "", 
    ssoEmailError: "",
  };

  handleSSOButtonClick = () => {
    this.setState({ showSSOModal: true, ssoEmailError: "" });
  };
  
  handleSSOModalClose = () => {
    this.setState({ showSSOModal: false, ssoEmail: "", ssoEmailError: "" });
  };

  handleSSOEmailChange = (e) => {
    this.setState({ ssoEmail: e.target.value, ssoEmailError: "" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.trySubmitted();

    const { user, password } = this.props;

    if (this.validateForm()) {
      this.props.sendForm({
        user,
        password,
      });
    }
  };

  handleOktaLogin = async () => {
    const { ssoEmail } = this.state;
    if (!utils.other.validateEmail(ssoEmail)) {
      this.setState({ ssoEmailError: "Please enter a valid email address for SSO." });
      return;
    }
    localStorage.setItem("oktaEmail", ssoEmail);
    try {
      const response = await fetch(`${OKTA_USERS_URL}?email=${ssoEmail}`);
      if (!response.ok) throw new Error("Failed to fetch tenant Okta configuration");
  
      const { authority, clientId } = await response.json();
  
      const oktaAuth = new OktaAuth({
        issuer: `https://${authority}`,
        clientId: clientId,
        redirectUri: `${window.location.origin}/okta/setToken`,
        scopes: ['openid', 'profile', 'email', 'groups'],
        responseType: 'code',
        pkce: false,
      });
  
      await oktaAuth.signInWithRedirect();
    } catch (error) {
      console.error("Okta login failed", error);
    }
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.changeField(name, value);
    this.validateField(name, value);
  };

  thirdPartyAuthenticate = (authType, token, id) => {
    this.props.thirdPartyAuthenticate({ authType, token, id });
  };

  validateField(fieldName, value) {
    let status;
    // let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regPass = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    switch (fieldName) {
      case "user":
        status = value.length >= 2;
        // status = value.length >= 2 && regEmail.test(value);
        break;
      case "password":
        status = value.length >= 8 && regPass.test(value);
        break;
      default:
        break;
    }

    this.props.changeFieldErrorStatus(fieldName, status);
  }

  validateForm() {
    return this.props.validFields.user && this.props.validFields.password;
  }

  componentDidMount() {
    if (getToken()) {
      supportPortalAuth();
      this.props.history.push("/");
    }
  }

  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisiblePassword: !prevState.isVisiblePassword,
    }));
  };

  render() {
    const {
      user,
      password,
      isTrySubmit,
      validFields,
      error,
      loading,
    } = this.props;
    const { isVisiblePassword } = this.state;

    const serverError = error ? (
      <div id="login_err_msg" className={styles.errorBlock}>
        {error}
      </div>
    ) : null;
    const loadingBtn = loading ? "" : null;

    const params = new URLSearchParams(window.location.search);
    const authType = params.get("authType");
    const token = params.get("token");
    const id = params.get("id");

    const submitForm = (
      <div>
        <h3>Sign in to your account</h3>
        <form onSubmit={this.handleSubmit}>
          {loading ? (
            <div className={styles.loader}>
              <Spinner height={100} />
            </div>
          ) : null}
          {serverError}
          <div
            className={`${styles.formGroup} ${
              !validFields.user && isTrySubmit ? styles.error : ""
            }`}
          >
            <input
              type="text"
              className="form-control"
              name="user"
              placeholder="E-Mail"
              value={user}
              onChange={this.handleInput}
            />
            <p className={styles.errorMsg}>Invalid Email Address</p>
          </div>
          <div
            className={`${styles.formGroup} ${
              !validFields.password && isTrySubmit ? styles.error : ""
            }`}
          >
            <div className={styles.eyeCont} onClick={this.toggleVisibility}>
              {isVisiblePassword ? (
                <img src={visibilityOn} />
              ) : (
                <img src={visibilityOff} />
              )}
            </div>
            <input
              type={isVisiblePassword ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Password"
              value={password}
              onChange={this.handleInput}
            />
            <p className={styles.errorMsg}>
              Min length 8 characters and must to be special characters(&!#..)
            </p>
          </div>
          <div className={styles.linkCont}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <button id="login_btn" type="submit" disabled={loading}>
            Login
          </button>
        </form>
      </div>
    );

    const authContainer = (
      <div>
        {serverError != null ? (
          serverError
        ) : (
          <div>
            <h3>Redirecting...</h3>
            <Spinner />
          </div>
        )}
      </div>
    );

    const sso = (
      <div>
      <div className={styles.ssoWrapper}>
        <button
          className={styles.ssoButton}
          onClick={this.handleSSOButtonClick}
        >
          <span className={styles.ssoButtonCircle}></span>
          LOGIN WITH OKTA
        </button>
      </div>

      {this.state.showSSOModal && (
  <Modal
    isOpen={this.state.showSSOModal}
    onRequestClose={this.handleSSOModalClose}
    contentLabel="SSO Email Input"
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
<div className={styles.modalContent}>
  <h2>Log in with SSO</h2>
  <p>Please enter your work email. We'll send you to your own login page.</p>
  <div className={styles.inputGroup}>
    <input
      type="email"
      placeholder="name@company.com"
      value={this.state.ssoEmail}
      onChange={this.handleSSOEmailChange}
      className={styles.modalInput}
    />
  </div>
  {this.state.ssoEmailError && (
    <p className={styles.errorMsg}>{this.state.ssoEmailError}</p>
  )}
  <div className={styles.modalActions}>
    <button
      className={styles.modalCancelButton}
      onClick={this.handleSSOModalClose}
    >
      Back to login <span className={styles.escKey}>ESC</span>
    </button>
    <button
      className={styles.modalButton}
      onClick={this.handleOktaLogin}
    >
      Submit <span className={styles.submitIcon}>&#x21b7;</span> {/* Submit icon */}
    </button>
  </div>
</div>
  </Modal>
)}
      </div>
    );
    

    const isThirdPartyAuth = authType != null && authType != AuthType.DEFAULT;
    if (!getToken() && isThirdPartyAuth) {
      this.thirdPartyAuthenticate(authType, token, id);
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.formCont}>
          <div className={styles.logoCont}>
            <img src={logo} alt="logo" />
          </div>
          <h1>
            Welcome to <br /> Bright Insights
          </h1>
          {isThirdPartyAuth ? authContainer : submitForm}
          {isThirdPartyAuth ? authContainer : sso}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.auth.login.data.user,
    password: state.auth.login.data.password,
    isTrySubmit: state.auth.login.isTrySubmit,
    validFields: state.auth.login.validFields,
    error: state.auth.login.error,
    loading: state.auth.login.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeField,
      trySubmitted,
      changeFieldErrorStatus,
      sendForm,
      thirdPartyAuthenticate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
