import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_CANCEL,
  CLEAR_USER,
} from "./constants";

const userReducer = (
  state = {
    userInfo: {
      firstName: "",
      lastName: "",
      email: "",
    },
    customerInfo: {
      name: "",
      rootDomain: "",
      logoImage: "",
      hasCategories: true,
    },
    screens: 0,
    loading: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_USER_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        ...data,
        loading: false,
      };
    }

    case FETCH_USER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case FETCH_USER_CANCEL: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_USER: {
      return {
        ...state,
        userInfo: {
          firstName: "",
          lastName: "",
          email: "",
        },
        customerInfo: {
          name: "",
          rootDomain: "",
          logoImage: "",
        },
        screens: 0,
        loading: false,
        error: "",
      };
    }
  }
  return state;
};

export default userReducer;
