import { ApiService, cancelUser } from "./apiService";
import AnalyticsService from "./analyticsService";

const apiService = new ApiService();
const analyticsService = new AnalyticsService();

export default class UserService {
  fetchUser() {
    if (cancelUser != undefined) {
      cancelUser("cancel");
    }

    const res = apiService
      .requestUserGET(`/user`)
      .then((res) => {
        return Promise.resolve(this._transformData(res.data));
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  _transformData = (data) => {
    analyticsService.setUser(data.userInfo.email);
    return {
      userInfo: {
        firstName: data.userInfo.firstName,
        lastName: data.userInfo.lastName,
        email: data.userInfo.email,
      },
      customerInfo: {
        name: data.customerInfo.name,
        rootDomain: data.customerInfo.rootDomain,
        logoImage: data.customerInfo.logoImage,
        hasCategories: data.customerInfo.hasCategories,
      },
      screens: data.screens,
    };
  };
}
