import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteDataTable from "../../../components/infinite-data-table/infinite-data-table";
import {
  changeSkusDetailId,
  fetchSkus,
  fetchTopSkus,
  fetchMoreSkus,
  fetchMoreTopSkus,
  changeSkusSorting,
  changeSkusOffset,
  changeSkusSelectedColumns,
} from "./actions";
import { setCurrentViewIsUpdated } from "../insights-data-controls/actions";

import {
  updateCurrentViewConfigQuery,
  showSidebarFilter,
} from "../../../containers/insights/actions";

import styles from "./insights-skus.scss";
import utils from "../../../utils";

import InsightsSkusTable from "./components/insights-skus-table";
import TopSkusTable from "./components/top-skus-table";
import MarketTrendsButtons from "../../../mb_components/market-trends-buttons/market-trends-buttons";
import MbBasicFilters from "../../../mb_components/mb-basic-filters/mb-basic-filters";
import Switch from "../../../components/switch/switch";
import {
  FREQUENCY_PRESETS,
  SKU_TRACKER_VIEW_TYPE_DATA,
} from "../../skus/constants";
import { convertSortValueAfterChangeFrequency } from "../../../utils/skus/convertSortValue";
import { analyticsMySkusToggled } from "../../shared/analytics/actions";

const { convertSortValueToColumn, convertSortValue2 } = utils.skus;

const { objectsEqual } = utils.other;

const BASIC_FILTERS_DISPLAY_NAMES = ["Category", "Retailer", "Map", "Text"];
const BASIC_FILTERS_MB_NAMES = [
  "mb_category.keyword",
  "map",
  "rootdomain.keyword",
  "text",
];

function replaceCharsInCombineFilterValues(productAttributes) {
  return productAttributes?.map((attr) => {
    // delete attr.displayName;

    return attr;
  });
}

function InsightsSkusInfiniteTable(props) {
  const {
    currentViewConfig,
    currentViewConfigQuery,
    insightsFiltersCollection,
    sortField,
    sortOrder,
    chosenPeriod,
    currentFrequency,
    viewTypeId,
    excludedTableColumns,
    initialSortField,
    marketTrendsConfigs,
    updateFiltersSelected,
    clearFiltersSelected,
    mySkusProductAttribute,
    insightsFilterShowSidebar,
    customerRootDomain,
    hasCategories,
  } = props;

  const [sortedColumnReset, setSortedColumnReset] = useState(false);
  // Used to allow selecting MySkus and another filter for TopSkus.
  const [mySkusSelected, setMySkusSelected] = useState(false);

  const [
    prevInsightsFiltersCollection,
    setPrevInsightsFiltersCollection,
  ] = useState([]);

  // Override the default sort field
  const [shouldOverrideSortField, setShouldOverrideSortField] = useState(true);
  const defaultSortField = 50;

  const selectedColumns =
    props.selectedColumns && props.selectedColumns.length > 0
      ? props.selectedColumns
      : props.defaultSelectedColumns;

  if (shouldOverrideSortField) {
    currentViewConfigQuery.sortField = initialSortField ?? defaultSortField;
    currentViewConfigQuery.productAttributes = mySkusProductAttribute;
    setShouldOverrideSortField(false);
  }

  const onSaveSelectedColumns = (selectedColumnsIds) => {
    props.changeSkusSelectedColumns(selectedColumnsIds);
    props.setCurrentViewIsUpdated();
  };

  const onSelectSku = (id) => {
    props.changeSkusDetailId(id, true);
    props.setCurrentViewIsUpdated();
  };

  const columnField = convertSortValueToColumn(
    viewTypeId,
    sortField,
    currentFrequency
  );
  const [selectedSortingDirection, setSelectedSortingDirection] = useState(
    false
  );

  const prepareSortingValues = (newSelectedSortingColumn) => {
    let sortDirection = false;
    if (columnField === newSelectedSortingColumn && selectedSortingDirection) {
      setSelectedSortingDirection(sortDirection);
    } else if (columnField === newSelectedSortingColumn) {
      sortDirection = true;
      setSelectedSortingDirection(sortDirection);
    } else {
      setSelectedSortingDirection(sortDirection);
    }

    const sortingConfig = {
      sortField: convertSortValue2(
        viewTypeId,
        newSelectedSortingColumn,
        currentFrequency
      ), //ToDO Convert
      sortOrder: sortDirection ? 0 : 1,
    };
    props.changeSkusSorting(sortingConfig);
    props.setCurrentViewIsUpdated();
  };

  const getCurrentSkusList = () => {
    const { activeCategory, level1ActiveCategory, skus } = props;
    const {
      productList,
      topCategoryLevel1ProductList,
      topCategoryLevel2ProductList,
    } = skus;

    if (level1ActiveCategory) {
      return topCategoryLevel2ProductList;
    }

    if (activeCategory) {
      return topCategoryLevel1ProductList;
    }

    return productList;
  };

  const handleFilterChange = () => {
    const { productAttributes } = currentViewConfigQuery;
    const updatedProductAttributes = replaceCharsInCombineFilterValues(
      productAttributes
    );
    const updatedCurrentViewConfigQuery = {
      ...currentViewConfigQuery,
      productAttributes: updatedProductAttributes,
    };

    if (viewTypeId == 0) {
      props.fetchSkus("productList", updatedCurrentViewConfigQuery, viewTypeId); //ToDO "ALL", 'MatchedSKUs', 'UnmatchedSKUs' Filter SKUS !!
    } else {
      props.fetchTopSkus("productList", updatedCurrentViewConfigQuery);
    }
  };

  const fetchMoreData = () => {
    const { productAttributes } = currentViewConfigQuery;
    const updatedProductAttributes = replaceCharsInCombineFilterValues(
      productAttributes
    );

    const lengthProduct = getCurrentSkusList()?.length;

    if (viewTypeId == 0) {
      props.fetchMoreSkus(
        lengthProduct,
        "productList",
        currentViewConfigQuery,
        updatedProductAttributes,
        viewTypeId
      ); //ToDO "ALL", 'MatchedSKUs', 'UnmatchedSKUs' Filter SKUS !!
    } else {
      props.fetchMoreTopSkus(
        lengthProduct,
        "productList",
        currentViewConfigQuery,
        updatedProductAttributes
      );
    }
  };

  useEffect(() => {
    handleFilterChange();
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (
      !objectsEqual(insightsFiltersCollection, prevInsightsFiltersCollection)
    ) {
      setPrevInsightsFiltersCollection(insightsFiltersCollection);
      if (
        insightsFiltersCollection.some(
          (item) =>
            (item.displayName === "Map" || item.mbName === "map") &&
            item.criteria[0].criterion === 4 &&
            item.criteria[0].value1 === 0
        )
      ) {
        if (!customerRootDomain) onSwitchMySkus(false);
        else setMySkusSelected(true);
      } else {
        setMySkusSelected(false);
      }
      handleFilterChange();
    }
  }, [insightsFiltersCollection]);

  const handleFrequencyChange = (id) => {
    const sortField = convertSortValueAfterChangeFrequency(
      SKU_TRACKER_VIEW_TYPE_DATA.id,
      currentViewConfigQuery.sortField,
      id
    );
    props.changeSkusSorting({ sortField });
    props.updateCurrentViewConfigQuery({
      queryFrequency: id,
      productAttributes: currentViewConfigQuery.productAttributes,
    });
    props.setCurrentViewIsUpdated();
  };

  useEffect(() => {
    handleFrequencyChange(FREQUENCY_PRESETS[chosenPeriod]);
  }, [chosenPeriod]);

  const { skus } = props;
  const { loading, hasMore } = skus;

  const productList = getCurrentSkusList();

  const getUpdatedFilters = (addedFilters, keepAllFilters = false) => {
    const filtersToKeep = keepAllFilters
      ? [...insightsFiltersCollection]
      : insightsFiltersCollection.filter(
          (collection) =>
            BASIC_FILTERS_DISPLAY_NAMES.includes(collection.displayName) ||
            BASIC_FILTERS_MB_NAMES.includes(collection.mbName)
        );
    addedFilters.forEach((filter) => {
      const index = filtersToKeep.findIndex((f) => f.mbName === filter.mbName);

      if (index !== -1) {
        filtersToKeep[index] = filter;
      } else {
        filtersToKeep.push(filter);
      }
    });
    return filtersToKeep;
  };

  const onChangeTrendCallback = (config) => {
    if (config.trendName === "Custom") {
      props.showSidebarFilter(true);
      return;
    }
    props.showSidebarFilter(false);
    const updatedFilters = getUpdatedFilters(
      config.overridingViewQuery.productAttributes
    );
    const sortingConfig = {
      sortField: config.overridingViewQuery.sortField,
      sortOrder: config.overridingViewQuery.sortOrder,
    };
    props.updateCurrentViewConfigQuery({
      productAttributes: updatedFilters,
      queryFrequency: config.overridingViewQuery.queryFrequency ?? "",
      sortField: sortingConfig.sortField,
      sortOrder: sortingConfig.sortOrder,
    });
    props.setCurrentViewIsUpdated();
    props.changeSkusSelectedColumns(config.overridingViewQuery.selectedColumns);
    props.changeSkusSorting(sortingConfig);
  };

  const onSwitchMySkus = (selected) => {
    if (!(mySkusProductAttribute?.length > 0)) return;
    let updatedFilters;
    if (selected)
      updatedFilters = getUpdatedFilters(mySkusProductAttribute, true);
    else {
      updatedFilters = insightsFiltersCollection.filter(
        (item) => item.mbName !== mySkusProductAttribute[0].mbName
      );
    }
    props.updateCurrentViewConfigQuery({
      productAttributes: updatedFilters,
    });
  };

  const onToggleSwitch = (value) => {
    analyticsMySkusToggled(viewTypeId, value);
    onSwitchMySkus(value);
    setMySkusSelected(value);
  };

  const table = (
    <div id="insights_skus" className={styles.wrapper}>
      {viewTypeId == 0 && (
        <InsightsSkusTable
          customerName={props.customerName}
          onSaveSelectedColumns={onSaveSelectedColumns}
          excludedTableColumns={excludedTableColumns}
          selectedColumns={selectedColumns}
          frequency={props.currentFrequency}
          sorting={{
            sortingColumn: columnField,
            selectedSortingDirection: !sortOrder,
          }}
          prepareSortingValues={prepareSortingValues}
          productList={productList}
          onSelectRow={onSelectSku}
          isLoading={loading}
        />
      )}
      {viewTypeId == 1 && (
        <TopSkusTable
          onSaveSelectedColumns={onSaveSelectedColumns}
          excludedTableColumns={excludedTableColumns}
          selectedColumns={selectedColumns}
          frequency={props.currentFrequency}
          sorting={{
            sortingColumn: columnField,
            selectedSortingDirection: !sortOrder,
          }}
          prepareSortingValues={prepareSortingValues}
          productList={productList}
          onSelectRow={onSelectSku}
          isLoading={loading}
        />
      )}
    </div>
  );

  const updateFilters = (filters) => {
    props.updateCurrentViewConfigQuery({
      productAttributes: filters,
    });
    props.setCurrentViewIsUpdated();
  };

  return (
    <div className={styles.infiniteTableWrapper}>
      {viewTypeId == 1 && (
        <div className={styles.tableControlsWrapper}>
          <div className={styles.basicFiltersWrapper}>
            {customerRootDomain && hasCategories && (
              <Switch
                toggled={mySkusSelected}
                onChange={onToggleSwitch}
                titleRight={"Show my SKUs only"}
                insightsFiltersCollection={insightsFiltersCollection}
              />
            )}
            <MbBasicFilters
              filterDefinitions={props.viewDefinitions?.filterDefinitions}
              insightsFiltersCollection={insightsFiltersCollection}
              updateFilters={updateFilters}
              viewTypeId={viewTypeId}
            />
          </div>
          <div>
            <MarketTrendsButtons
              marketTrendsConfigs={marketTrendsConfigs}
              currentViewConfigQuery={currentViewConfig.query}
              viewName={currentViewConfig?.name}
              onChangeTrendCallback={onChangeTrendCallback}
              viewTypeId={viewTypeId}
              clearFiltersSelected={clearFiltersSelected}
              updateFiltersSelected={updateFiltersSelected}
              insightsFilterShowSidebar={insightsFilterShowSidebar}
              mySkusSelected={mySkusSelected}
            />
          </div>
        </div>
      )}
      <>
        <InfiniteDataTable
          table={table}
          dataLength={productList.length}
          hasMore={hasMore}
          fetchMoreData={fetchMoreData}
          loading={loading}
          loadingMore={skus.loadingMore}
        />
      </>
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    customerName: state.user.customerInfo.name,
    customerRootDomain: state.user.customerInfo.rootDomain,
    hasCategories: state.user.customerInfo.hasCategories,
    loading: state.insights.insights.loading,
    skus: state.insights.insights.skus,
    byCompetitors: state.insights.insights.byCompetitors,
    byCompetitorsLevel1:
      state.insights.insights.topCategoryLevel1.byCompetitors,
    byCompetitorsLevel2:
      state.insights.insights.topCategoryLevel2.byCompetitors,
    byCategories: state.insights.insights.byCategories,
    byCategoriesLevel1: state.insights.insights.topCategoryLevel1.byCategories,
    byCategoriesLevel2: state.insights.insights.topCategoryLevel2.byCategories,
    competitorSelected: state.insights.insights.competitor.rootDomain,

    //ToDo should refactored
    insightsFilterShowSidebar:
      state.insights.insights.insightsFilterShowSidebar,
    marketTrendsConfigs: state.insights.insights.marketTrendsConfigs,
    clearFiltersSelected: state.insights.insights.clearFiltersSelected,
    updateFiltersSelected: state.insights.insights.updateFiltersSelected,
    currentViewConfig: state.insights.insights.currentViewConfig,
    currentViewConfigQuery: state.insights.insights.currentViewConfig.query,
    currentFrequency:
      state.insights.insights.currentViewConfig.query.queryFrequency,
    insightsFiltersCollection:
      state.insights.insights.currentViewConfig.query.productAttributes,
    sortField: state.insights.insights.currentViewConfig.query.sortField,
    sortOrder: state.insights.insights.currentViewConfig.query.sortOrder,
    chosenPeriod: state.insights.mbFilterRow.chosenPeriod,
    selectedColumns:
      state.insights.insights.currentViewConfig.query.selectedColumns,
    offset: state.insights.insights.currentViewConfig.query.offset,
    viewDefinitions: state.insights.insights.viewDefinitions,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSkusDetailId,
      setCurrentViewIsUpdated,
      fetchSkus,
      fetchTopSkus,
      fetchMoreSkus,
      fetchMoreTopSkus,
      changeSkusSorting,
      changeSkusOffset,
      changeSkusSelectedColumns,
      updateCurrentViewConfigQuery,
      showSidebarFilter,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsSkusInfiniteTable);

// If I click on customize columns add *days OOS *
// then click on sort then days OOS disappears from the view and the sorting condition is not applied
